import { Injectable } from "@angular/core";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import { BehaviorSubject, fromEvent, Observable, Subscription } from "rxjs";
import { AppLoadingHelper } from "./app-loading-helper";

export declare interface NpAppSba {
    toggle(): void;
}

export declare type NpViewSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

@Injectable()
export class NpAppLayoutService {

    private _subscriptions: Subscription[] = [];

    private _appLoadingHelper = new AppLoadingHelper();
    public get appLoader() {
        return this._appLoadingHelper;
    }

    protected _viewSizeBs: BehaviorSubject<NpViewSize>;
    public get viewSizeOsb(): Observable<NpViewSize> {
        return this._viewSizeBs.asObservable();
    }
    public get viewSize() {
        return this._viewSizeBs.value;
    }

    constructor(private router: Router) {

        let nextViewSize = this.chkNpViewSize(window.innerWidth);
        this._viewSizeBs = new BehaviorSubject<NpViewSize>(nextViewSize);

        let resizeSubscription = fromEvent(window, 'resize').subscribe((event: any) => {
            let currentViewSize = this.chkNpViewSize(event.target.innerWidth);
            if (this.viewSize !== currentViewSize) {
                this._viewSizeBs.next(currentViewSize);
            }
        });
        this._subscriptions.push(resizeSubscription);

        // 라우팅이 일어나면 mobile & tablet size에서 toggle 변경사항을 초기화 해 준다.
        let routerEventsSubscription = router.events.subscribe((e: any) => {
            if (e instanceof NavigationStart) {
                this._appLoadingHelper.setNavigating(true);
            } else if (e instanceof NavigationEnd) {
                this._appLoadingHelper.setNavigating(false);
            }
        });
        this._subscriptions.push(routerEventsSubscription);
    }

    chkNpViewSize(width: number): NpViewSize {
        if (width >= 1400) {
            return 'xxl';
        } else if (width >= 1200) {
            return 'xl';
        } else if (width >= 992) {
            return 'lg';
        } else if (width >= 768) {
            return 'md';
        } else if (width >= 576) {
            return 'sm';
        } else {
            return 'xs';
        }
    }

    protected _npViewSba: any;

    registSba(sba: NpAppSba) {
        setTimeout(() => {
            this._npViewSba = sba;
        }, 100);
    }

    unregistSba(sba: NpAppSba): boolean {
        if (this.sba && this.sba === sba) {
            this._npViewSba = null;
            return true;
        } else {
            return false;
        }
    }

    public get sba(): NpAppSba {
        return this._npViewSba;
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach((sb) => sb.unsubscribe());
    }

}

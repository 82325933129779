import { BehaviorSubject } from "rxjs";

export class AppLoadingHelper {

    private _appInitedBs: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public get appInited() {
        return this._appInitedBs.asObservable();
    }

    /**
     * 페이지 로딩 여부 (앱 최초 로딩 후 동작함)
     */
    private _loadingBs: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public get loading() {
        return this._loadingBs.asObservable();
    }

    setNavigating(value: boolean) {

        if (value) {
            // true = loading
            if (this._appInitedBs.value === true) {
                this._loadingBs.next(true);
            }

        } else {
            // false = loaded
            if (this._appInitedBs.value === false) {
                this._appInitedBs.next(true);
            }

            this._loadingBs.next(false);

        }

    }

    /**
     * 페이지 로딩 여부 (앱 최초 로딩 후 동작함)
     */
    private _pageLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public get pageLoading() {
        return this._pageLoading.asObservable();
    }

    setPageLoading(value: boolean) {
        this._pageLoading.next(value);
    }
}